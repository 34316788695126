import { useState, useEffect } from "react";
import AddProfilesGridModel from "../../../models/company/addProfiles/response/AddProfilesGridModel";
import IdNameModel from "../../../models/common/IdNameModel";
import addProfilesService from "../../../services/company/add-profiles-service";
import alertUtils from "../../../utils/toaster-utils";
import InterviewRoundsPopUp from "./ScheduleInterviewRoundsComponent";
import ScheduleInterviewPopUp from "./ScheduleInterviewComponent";
import { GeneralPoupConfig, ModalSize } from "../../../models/modal-types";
import AddProfilesInterviewRoundsModel from "../../../models/company/addProfiles/response/AddProfilesInterviewRoundsModel";
import modalUtils from "../../../utils/modal-utils";
import ScheduleInterviewCompanyRequestModel from "../../../models/company/addProfiles/request/ScheduleInterviewCompanyRequestModel";
import { GuidEnum } from "../../../models/enums/GuidEnum";
import ScheduleInterviewRequest from "../../../models/candidate/request/ScheduleInterviewRequest";
import { InterviewTypeEnum } from "../../../models/enums/InterviewTypeEnum";
import { useAppSelector } from "../../../context-store";
import { RoleEnum } from "../../../models/enums/RoleEnum";
import loaderUtils from "../../../utils/loader-utils";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import TablePagination from "@mui/material/TablePagination";
import TableContainer from "@mui/material/TableContainer";
import TableSortLabel from "@mui/material/TableSortLabel";
import TableHead from "@mui/material/TableHead";
import copyClickboardUtils from "../../../utils/copy-clipboard";

interface AddProfilesGridProps {
    addProfilesList: AddProfilesGridModel[];
    requirementId: string;
    timeSlotsList: IdNameModel[];
    totalSize: number;
    currentPage: number;
    pagesize: number;
    sortColumn: string;
    sortDirection: "asc" | "desc";
    pageSizesList: number[];
    candidateNameFilterValue: string;
    statusIdFilterValue: number;
    onProfileAdd: (actionType: string) => void;
    onStatusUpdate: (row: AddProfilesGridModel) => void;
    onScheduleInterview: (row: ScheduleInterviewCompanyRequestModel) => void;
    onRatingDetails: (interviewId: string) => void;
    onPageChangeAction: (newPage: number) => void;
    onPageSizeChangeAction: (newPageSize: number) => void;
    onSortAction: (newSortColumn: string, sortDir: string) => void;
    onFilterAction: (filterColumn: string, filterValue: string) => void;
}

const AddProfilesGridComponent = (props: AddProfilesGridProps) => {
    let loggedInUser = useAppSelector((state) => state.authState.loggedInUser);
    const [roleId, setRoleId] = useState<number>(loggedInUser !== null ? parseInt(loggedInUser.roleId) : 0);
    const [candidateNameFilter, setCandidateNameFilter] = useState<string>(props.candidateNameFilterValue);
    const [statusIdFilter, setStatusIdFilter] = useState<number>(props.statusIdFilterValue);
    const ActionFormatter = (
        cell: any,
        row: AddProfilesGridModel,
        rowIndex: number
    ) => {
        return (
            <div className="text-center-align">
                {(roleId === parseInt(RoleEnum.ClientRecruiter.toString()) || roleId === parseInt(RoleEnum.Administrator.toString())
                    || roleId === parseInt(RoleEnum.IAIRecruiter.toString())) &&
                    <div>
                        <a onClick={() => { statusUpdate(row); }}>Update Status</a>
                        <a onClick={() => { scheduleInterview(row); }}>Schedule Interview</a>
                    </div>
                }
            </div>
        );
    };

    const statusUpdate = (row: AddProfilesGridModel) => {
        props.onStatusUpdate(row);
    };

    const openInterviewRoundsModel = (interviewRounds: AddProfilesInterviewRoundsModel[], objAddProfile: AddProfilesGridModel) => {
        const config: GeneralPoupConfig = {
            title: "Schedule Interview Rounds",
            content: (
                <InterviewRoundsPopUp
                    objAddProfile={objAddProfile}
                    interviewRoundsList={interviewRounds}
                    onCloseInterviewRounds={onCloseInterviewRoundsHandler}
                    onInterviewRoundSelect={onInterviewRoundSelectHandler}
                    onInterviewRatingDetails={onInterviewRatingDetailsHandler}
                />
            ),
            size: ModalSize.LG,
            className: "model-min-height-500"
        };
        modalUtils.showPopup(config);
    };

    const onCloseInterviewRoundsHandler = () => {
        modalUtils.closeModal();
    };

    const onInterviewRoundSelectHandler = (objInterviewRound: AddProfilesInterviewRoundsModel, objAddProfile: AddProfilesGridModel) => {
        openScheduleInterviewModel(objInterviewRound, objAddProfile);
    };

    const onInterviewRatingDetailsHandler = (objInterviewRound: AddProfilesInterviewRoundsModel, objAddProfile: AddProfilesGridModel) => {
        props.onRatingDetails(objInterviewRound.interviewId);
    };

    const openScheduleInterviewModel = (objInterviewRound: AddProfilesInterviewRoundsModel, objAddProfile: AddProfilesGridModel) => {
        let scheduleInterview: ScheduleInterviewRequest = {
            candidateId: objAddProfile.candidateId,
            interviewDate: new Date(),
            timeSlotId: 0,
            interviewTypeId: InterviewTypeEnum.Audio,
            primarySkillId: 0,
            secondarySkills: [],
            termsAndConditions: true,
            requirementId: objAddProfile.requirementId,
            interviewId: objInterviewRound.interviewId
        };
        let objScheduleInterview: ScheduleInterviewCompanyRequestModel = {
            profileId: objAddProfile.profileId,
            userId: GuidEnum.Empty,
            requirementInterviewProcessId: objInterviewRound.requirementInterviewProcessId,
            requirementScheduleId: objInterviewRound.requirementScheduleId,
            interviewId: objInterviewRound.interviewId,
            ScheduleInterviewRequestModel: scheduleInterview
        }
        const config: GeneralPoupConfig = {
            title: objInterviewRound.interviewId === null ? "Schedule Interview" : "Re Schedule Interview",
            content: (
                <ScheduleInterviewPopUp
                    objScheduleInterview={objScheduleInterview}
                    timeSlotsList={props.timeSlotsList}
                    onCloseScheduleInterview={onCloseScheduleInterviewHandler}
                    onScheduleInterviewSave={onScheduleInterviewSaveHandler}
                />
            ),
            size: ModalSize.LG,
            className: "model-min-height-500"
        };
        modalUtils.showPopup(config);
    };

    const onCloseScheduleInterviewHandler = () => {
        modalUtils.closeModal();
    };

    const onScheduleInterviewSaveHandler = (objScheduleInterview: ScheduleInterviewCompanyRequestModel) => {
        props.onScheduleInterview(objScheduleInterview);
    };

    const scheduleInterview = (row: AddProfilesGridModel) => {
        modalUtils.showLoader(loaderUtils.getLoadDataText());
        addProfilesService.getRequirementInterviewRounds(row.requirementId, row.profileId).then((res) => {
            modalUtils.closeModal();
            if (res.items.length > 0) {
                //if (row.addInterviewProcess) {
                openInterviewRoundsModel(res.items, row);
                // } else {
                //     openScheduleInterviewModel(res.items[0], row);
                // }
            } else {
                alertUtils.showError("No Data Found");
            }
        }).catch((error) => {
            modalUtils.closeModal();
            alertUtils.showError(error);
        });
    };

    const copyClipboard = (message: string) => {
        copyClickboardUtils.copy(message);
    };

    const columnsAddProfile = [
        { key: "CandidateName", text: "Candidate Name", sort: true, searchable: true },
        { key: "EmailId", text: "Email Id", sort: true, searchable: false },
        { key: "MobileNumber", text: "Mobile No", sort: true, searchable: false },
        { key: "Comments", text: "Comments", sort: false, searchable: false },
        { key: "Actions", text: "", sort: false, searchable: false }
    ];

    const createSortHandler = (property: string) => (event: React.MouseEvent) => {
        props.onSortAction(property, props.sortDirection === "asc" ? "desc" : "asc")
    };

    const handleChangePage = (event: any, newPage: number) => {
        props.onPageChangeAction(newPage);
    };

    const handleChangeRowsPerPage = (event: any) => {
        props.onPageSizeChangeAction(parseInt(event.target.value, 10));
    };

    const filterOnChange = (key: string, value: string) => {
        if (key === "CandidateName") {
            setCandidateNameFilter(value);
        } else {
            setStatusIdFilter(parseInt(value));
        }
        if ((value && value.length > 2) || value === "") {
            props.onFilterAction(key, value);
        }
    };

    return (
        <div className="row">
            <div className="col-md-12">
                <TableContainer>
                    <Table>
                        <TableHead>
                            <TableRow>
                                {columnsAddProfile.map((column: any) => (
                                    <TableCell key={column.key} className="table-header-cell">
                                        {column.sort &&
                                            <TableSortLabel
                                                active={props.sortColumn === column.key}
                                                direction={props.sortColumn === column.key ? props.sortDirection : "asc"}
                                                onClick={createSortHandler(column.key)}>
                                                {column.text}
                                            </TableSortLabel>
                                        }
                                        {column.searchable &&
                                            <input type="text" placeholder="search" className="mat-header-filter-input" value={candidateNameFilter}
                                                onClick={(e) => e.stopPropagation()} onChange={(e) => filterOnChange(column.key, e.target.value)}></input>
                                        }
                                        {!column.sort &&
                                            <span>{column.text}</span>
                                        }
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {props.addProfilesList.map((profile: AddProfilesGridModel, index) => (
                                <TableRow>
                                    <TableCell>{profile.candidateName}</TableCell>
                                    <TableCell>{profile.emailId}</TableCell>
                                    <TableCell>
                                        <span>{profile.mobileNumber}</span><a title="copy" onClick={() => { copyClipboard(profile.mobileNumber.toString()); }}><i className="fa fa-clipboard"></i></a>
                                    </TableCell>
                                    <TableCell>{profile.comments}</TableCell>
                                    <TableCell>
                                        {(roleId === parseInt(RoleEnum.ClientRecruiter.toString()) || roleId === parseInt(RoleEnum.Administrator.toString())
                                            || roleId === parseInt(RoleEnum.IAIRecruiter.toString())) &&
                                            <div>
                                                <a onClick={() => { statusUpdate(profile); }} title="Update Status">
                                                    <i className="fa fa-sync-alt grid-icon blue"></i>
                                                </a>
                                                <a onClick={() => { scheduleInterview(profile); }} title="Schedule Interview">
                                                    <i className="fa fa-calendar-alt grid-icon blue"></i>
                                                </a>
                                            </div>
                                        }
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={props.pageSizesList}
                    component="div"
                    count={props.totalSize}
                    rowsPerPage={props.pagesize}
                    page={props.currentPage - 1}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </div>
        </div>
    );
};
export default AddProfilesGridComponent;