import React, { useState } from "react";
import { Redirect, useHistory } from "react-router";
import { useAppSelector } from "../../../context-store";
import JobPostingRequestModel from "../../../models/company/jobPosting/request/JobPostingRequestModel";
import { PageModeEnum } from "../../../models/enums/PageModeEnum";
import { GuidEnum } from "../../../models/enums/GuidEnum";
import JobPostingModel from "../../../models/company/jobPosting/response/JobPostingModel";
import JobPostingSkillRequestModel from "../../../models/company/jobPosting/request/JobPostingSkillRequestModel";
import { InterviewTypeEnum } from "../../../models/enums/InterviewTypeEnum";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import TablePagination from "@mui/material/TablePagination";
import TableContainer from "@mui/material/TableContainer";
import TableSortLabel from "@mui/material/TableSortLabel";
import TableHead from "@mui/material/TableHead";

interface JobPostingGridProps {
    jobPostingList: JobPostingModel[];
    totalSize: number;
    currentPage: number;
    pagesize: number;
    sortColumn: string;
    sortDirection: "asc" | "desc";
    pageSizesList: number[];
    jobCodeFilterValue: string;
    onJobPostingAction: (row: JobPostingRequestModel, actionType: string) => void;
    onJobPostingVendorAction: (requirementId: string) => void;
    onPageChangeAction: (newPage: number) => void;
    onPageSizeChangeAction: (newPageSize: number) => void;
    onSortAction: (newSortColumn: string, sortDir: string) => void;
    onFilterAction: (filterColumn: string, filterValue: string) => void;
}

const JobPostingGridComponent = (props: JobPostingGridProps) => {
    let loggedInUser = useAppSelector((state) => state.authState.loggedInUser);
    const [jobCodeFilter, setJobCodeFilter] = useState<string>(props.jobCodeFilterValue);
    const history = useHistory();

    const ActionFormatter = (
        cell: any,
        row: JobPostingModel,
        rowIndex: number
    ) => {
        return (
            <div className="text-center-align">
                <a style={{ display: 'block' }} onClick={() => { editJobPosting(row); }}>Edit</a>
                {row.addInterviewProcess &&
                    <a style={{ display: 'block' }} onClick={() => { setInterviewProcess(row); }}>InterView Process</a>
                }
                <a style={{ display: 'block' }} onClick={() => { props.onJobPostingVendorAction(row.requirementId); }}>Add Vendors</a>
            </div>
        );
    };

    const editJobPosting = (row: JobPostingModel) => {
        if (loggedInUser !== null) {
            let jobPostingRequestModel: JobPostingRequestModel = {
                requirementId: row.requirementId,
                companyId: row.companyId,
                recruiterId: loggedInUser.profileId,
                jobTypeId: null,
                jobCode: row.jobCode,
                jobDescription: "",
                keyResponsibilities: "",
                domainId: null,
                additionalSkills: "",
                primarySkillId: null,
                minExperienceId: null,
                maxExperienceId: null,
                startDate: new Date(),
                endDate: new Date,
                cityId: null,
                designationId: "",
                highestPay: "",
                interviewTypeId: null,
                assessmentName: "",
                scheduleZoomInterview: true,
                addInterviewProcess: false,
                statusId: null,
                sendMailToRecruiter: true,
                captureScreenShot: true,
                minRatingForSelection: 0,
                jobPostingSkillRequestModel: [],
                userId: loggedInUser.userId
            }
            props.onJobPostingAction(jobPostingRequestModel, PageModeEnum.Edit);
        }
    };

    const setInterviewProcess = (row: JobPostingModel) => {
        history.push("/interview-process/" + row.requirementId);
    };

    const columnsJobPostings = [
        { key: "JobCode", text: "Job Code", sort: true, searchable: true },
        { key: "JobTitle", text: "Job Title", sort: true, searchable: false },
        { key: "JobTypeName", text: "Job Type", sort: true, searchable: false },
        { key: "MinExperience", text: "Min Exp", sort: false, searchable: false },
        { key: "MaxExperience", text: "Max Exp", sort: false, searchable: false },
        { key: "HighestPay", text: "Highest Pay", sort: false, searchable: false },
        { key: "StartDate", text: "Posting St Dt", sort: false, searchable: false },
        { key: "EndDate", text: "Posting End Dt", sort: false, searchable: false },
        { key: "PrimarySkillName", text: "Primary Skill", sort: true, searchable: false },
        { key: "Status", text: "Status", sort: true, searchable: false },
        { key: "Actions", text: "Actions", sort: false, searchable: false }
    ];

    const createSortHandler = (property: string) => (event: React.MouseEvent) => {
        props.onSortAction(property, props.sortDirection === "asc" ? "desc" : "asc")
    };

    const handleChangePage = (event: any, newPage: number) => {
        props.onPageChangeAction(newPage);
    };

    const handleChangeRowsPerPage = (event: any) => {
        props.onPageSizeChangeAction(parseInt(event.target.value, 10));
    };

    const filterOnChange = (key: string, value: string) => {
        setJobCodeFilter(value);
        if ((value && value.length > 2) || value === "") {
            props.onFilterAction(key, value);
        }
    };

    return (
        <div className="row">
            <div className="col-md-12">
                <TableContainer>
                    <Table>
                        <TableHead>
                            <TableRow>
                                {columnsJobPostings.map((column: any) => (
                                    <TableCell key={column.key} className="table-header-cell">
                                        {column.sort &&
                                            <TableSortLabel
                                                active={props.sortColumn === column.key}
                                                direction={props.sortColumn === column.key ? props.sortDirection : "asc"}
                                                onClick={createSortHandler(column.key)}>
                                                {column.text}
                                            </TableSortLabel>
                                        }
                                        {column.searchable &&
                                            <input type="text" placeholder="search" className="mat-header-filter-input" value={jobCodeFilter}
                                                onClick={(e) => e.stopPropagation()} onChange={(e) => filterOnChange(column.key, e.target.value)}></input>
                                        }
                                        {!column.sort &&
                                            <span>{column.text}</span>
                                        }
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {props.jobPostingList.map((row: JobPostingModel, index) => (
                                <TableRow>
                                    <TableCell>{row.jobCode}</TableCell>
                                    <TableCell>{row.jobTitle}</TableCell>
                                    <TableCell>{row.jobTypeName}</TableCell>
                                    <TableCell>{row.minExperience}</TableCell>
                                    <TableCell>{row.maxExperience}</TableCell>
                                    <TableCell>{row.highestPay}</TableCell>
                                    <TableCell>{row.startDate}</TableCell>
                                    <TableCell>{row.endDate}</TableCell>
                                    <TableCell>{row.primarySkillName}</TableCell>
                                    <TableCell>{row.status}</TableCell>
                                    <TableCell>
                                        <a style={{ display: 'block' }} onClick={() => { editJobPosting(row); }} title="Edit">
                                            <i className="far fa-edit grid-icon blue"></i>
                                        </a>
                                        {row.addInterviewProcess &&
                                            <a style={{ display: 'block' }} onClick={() => { setInterviewProcess(row); }} title="InterView Process">
                                                <i className="fa fa-calendar-plus grid-icon blue"></i>
                                            </a>
                                        }
                                        <a style={{ display: 'block' }} onClick={() => { props.onJobPostingVendorAction(row.requirementId); }} title="Add Vendors">
                                            <i className="fa fa-address-card grid-icon blue"></i>
                                        </a>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={props.pageSizesList}
                    component="div"
                    count={props.totalSize}
                    rowsPerPage={props.pagesize}
                    page={props.currentPage - 1}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </div>
        </div>
    );
};
export default JobPostingGridComponent;