import React, { useState, useEffect } from "react";
import { useHistory } from "react-router";
import { useAppSelector } from "../../../../context-store";
import adminClasses from "../../../landing-pages/admin/admin.module.css";
import IdNameModel from "../../../../models/common/IdNameModel";
import CompanyProfilesDashboardModel from "../../../../models/admin/company/response/CompanyProfilesDashboardModel";
import CompanyProfilesDashboardSeachInputModel from "../../../../models/admin/company/request/CompanyProfilesDashboardSeachInputModel";
import { SortDirection } from "../../../../models/enums/SortDirectionEnum";
import modalUtils from "../../../../utils/modal-utils";
import loaderUtils from "../../../../utils/loader-utils";
import adminCompanyService from "../../../../services/admin/admin-company-service";
import alertUtils from "../../../../utils/toaster-utils";
import CompanyProfilesDashboardGridComponent from "./CompanyProfilesDashboardGridComponent";
import DateTimePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import dateTimeUtils from "../../../../utils/date-utils";
import { PagingEnum } from "../../../../models/enums/IAIEnum";
import masterDataService from "../../../../services/master-data-service";

interface CompanyProfilesDashboardProps {
    companiesList: IdNameModel[];
};

const CompanyProfilesDashboardComponent = (props: CompanyProfilesDashboardProps) => {
    const [companyList, setCompanyList] = useState<CompanyProfilesDashboardModel[]>([]);
    const [refreshCompany, setRefreshCompany] = useState<number>(2);
    const [startDateSearch, setStartDateSearch] = useState(dateTimeUtils.addDays(new Date(), -30) as any);
    const [endDateSearch, setEndDateSearch] = useState(new Date() as any);
    const [companySearch, setCompanySearch] = useState<string>("");
    const [page, setPage] = useState<number>(PagingEnum.page);
    const [pageSize, setPageSize] = useState<number>(PagingEnum.pageSize);
    const [sortColumn, setSortColumn] = useState<string>("CompanyName");
    const [sortDirection, setSortDirection] = useState<number>(PagingEnum.sortDirection);
    const [pageSizesList, setPageSizesList] = useState<number[]>([]);

    useEffect(() => {
        loadPageSizes();
        clearSearch();
    }, []);

    const loadPageSizes = () => {
        masterDataService.loadPageSizesForPaging().then((res) => {
            setPageSizesList(res);
        }).catch((error) => {
            alertUtils.showError(error);
        });
    };

    const clearSearch = () => {
        setCompanyList([]);
        setStartDateSearch("");
        setEndDateSearch("");
        setCompanySearch("");
        setRefreshCompany(2);
    };

    const startDateHanler = (date: any) => {
        setStartDateSearch(date);
    };

    const endDateHanler = (date: any) => {
        setEndDateSearch(date);
    };

    const getSerchFilters = (page: number, pageSize: number, sortColumn: string, sortDirection: number) => {
        var inputData: CompanyProfilesDashboardSeachInputModel = {
            startDate: startDateSearch,
            endDate: endDateSearch,
            companyId: companySearch,
            page: page,
            pageSize: pageSize,
            sort: sortColumn,
            sortDir: sortDirection
        };
        return inputData;
    }

    const getCompanyDashboard = (inputData: CompanyProfilesDashboardSeachInputModel) => {
        if (companySearch === "" || startDateSearch === "" || endDateSearch === "") {
            alertUtils.showWarning("Please select Company, Start Date dnd End Date");
            return;
        } else if (startDateSearch > endDateSearch) {
            alertUtils.showWarning("Start date cannot be greater than End Date");
            return;
        }
        setRefreshCompany(2);
        modalUtils.showLoader(loaderUtils.getLoadDataText());
        adminCompanyService.getCompanyProfilesDashboard(inputData).then((res) => {
            modalUtils.closeModal();
            setCompanyList([]);
            setCompanyList(res.items);
            setRefreshCompany(refreshCompany * 5);
        }).catch((error) => {
            setRefreshCompany(refreshCompany * 5);
            modalUtils.closeModal();
            alertUtils.showError(error);
        });
    };

    const onPageChangeActionHandler = (newPage: number) => {
        setPage(newPage);
        getCompanyDashboard(getSerchFilters(newPage, pageSize, sortColumn, sortDirection));
    };

    const onPageSizeChangeActionHandler = (newPageSize: number) => {
        setPageSize(newPageSize);
        setPage(PagingEnum.page);
        getCompanyDashboard(getSerchFilters(PagingEnum.page, newPageSize, sortColumn, sortDirection));
    };

    const onSortActionHandler = (newSortColumn: string, sortDir: string) => {
        setSortColumn(newSortColumn);
        setSortDirection(sortDir === "asc" ? SortDirection.Asc : SortDirection.Desc);
        getCompanyDashboard(getSerchFilters(page, pageSize, newSortColumn, sortDir === "asc" ? SortDirection.Asc : SortDirection.Desc));
    };

    return (
        <div className="card card-white">
            <div className="card-body">
                <div className="row">
                    <div className="col-md-3">
                        <label>Company</label>
                        <select className="mb-3 form-control form-select" onChange={(e) => setCompanySearch(e.target.value)}>
                            <option value="">Select Company</option>
                            {props.companiesList.map((item, index) => {
                                return (
                                    <option key={index} value={item.guId}> {item.name} </option>
                                );
                            })}
                        </select>
                    </div>
                    <div className="col-md-3">
                        <label>Start Date</label>
                        <DateTimePicker
                            selectsStart
                            onChange={startDateHanler}
                            selected={startDateSearch}
                            timeInputLabel="Time:"
                            dateFormat="yyyy-MMM-dd"
                            className="form-control"
                            onKeyDown={(e) => {
                                e.preventDefault();
                            }}
                        />
                    </div>
                    <div className="col-md-3">
                        <label>End Date</label>
                        <DateTimePicker
                            selectsStart
                            onChange={endDateHanler}
                            selected={endDateSearch}
                            timeInputLabel="Time:"
                            dateFormat="yyyy-MMM-dd"
                            className="form-control"
                            onKeyDown={(e) => {
                                e.preventDefault();
                            }}
                        />
                    </div>
                    <div className="col-md-3">
                        <button className="btn btn-success" type="submit" onClick={() => getCompanyDashboard(getSerchFilters(page, pageSize, sortColumn, sortDirection))}>
                            <i className="icon-search">Search</i>
                        </button>
                        <button className="btn btn-warning" type="submit" onClick={() => clearSearch()}>
                            <i className="icon-search">Clear</i>
                        </button>
                    </div>
                </div>
                {companyList.length > 0 && refreshCompany > 2 &&
                    <CompanyProfilesDashboardGridComponent
                        key={refreshCompany}
                        companyList={companyList}
                        totalSize={companyList.length}
                        currentPage={page + 1}
                        pagesize={pageSize}
                        sortColumn={sortColumn}
                        sortDirection={sortDirection === SortDirection.Asc ? "asc" : "desc"}
                        pageSizesList={pageSizesList}
                        onPageChangeAction={onPageChangeActionHandler}
                        onPageSizeChangeAction={onPageSizeChangeActionHandler}
                        onSortAction={onSortActionHandler} />
                }
            </div>
        </div>
    );
};
export default CompanyProfilesDashboardComponent;