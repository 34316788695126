import { useState, MouseEvent } from "react";
import { useHistory } from "react-router";
import { useAppSelector } from "../../../context-store";
import adminClasses from "../../../landing-pages/admin/admin.module.css";
import { PageModeEnum } from "../../../models/enums/PageModeEnum";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import TablePagination from "@mui/material/TablePagination";
import TableContainer from "@mui/material/TableContainer";
import TableSortLabel from "@mui/material/TableSortLabel";
import TableHead from "@mui/material/TableHead";
import InterviewerDashboardModel from "../../../models/interviewer/response/InterviewerDashboardModel";
import { ConfirmEnum } from "../../../models/enums/ConfirmEnum";

interface InterviewerDashboardGridProps {
    interviewerDashboardList: InterviewerDashboardModel[];
    totalSize: number;
    currentPage: number;
    pagesize: number;
    sortColumn: string;
    sortDirection: "asc" | "desc";
    pageSizesList: number[];
    candidateNameFilterValue: string;
    onConfirmInterviewAction: (row: InterviewerDashboardModel) => void;
    onRateInterviewAction: (row: InterviewerDashboardModel) => void;
    onPageChangeAction: (newPage: number) => void;
    onPageSizeChangeAction: (newPageSize: number) => void;
    onSortAction: (newSortColumn: string, sortDir: string) => void;
    onFilterAction: (filterColumn: string, filterValue: string) => void;
};

const InterviewerDashboardGridComponent = (props: InterviewerDashboardGridProps) => {
    const [candidateNameFilter, setCandidateNameFilter] = useState<string>(props.candidateNameFilterValue);
    let loggedInUser = useAppSelector((state) => state.authState.loggedInUser);

    const columnsInterviews = [
        { key: "InterviewDate", text: "Interview Date", sort: true, searchable: false },
        { key: "TimeSlotName", text: "Time", sort: true, searchable: false },
        { key: "PrimarySkillName", text: "Primary Skill", sort: true, searchable: false },
        { key: "CandidateName", text: "Candidate Name", sort: true, searchable: true },
        { key: "InterviewTypeName", text: "Interview Type", sort: false, searchable: false },
        { key: "IsConfirmedByInterviewer", text: "Confirmed", sort: true, searchable: false },
        { key: "Rating", text: "Rating", sort: false, searchable: false },
        { key: "StatusName", text: "Status", sort: false, searchable: false },
        { key: "MeetingURL", text: "Meeting Link", sort: false, searchable: false },
        // { dataField: "meetingURL", text: "Spl Notes", sort: false, searchable: false },
        { key: "Actions", text: "", sort: false, searchable: false }
    ];

    const createSortHandler = (property: string) => (event: React.MouseEvent) => {
        props.onSortAction(property, props.sortDirection === "asc" ? "desc" : "asc")
    };

    const handleChangePage = (event: any, newPage: number) => {
        props.onPageChangeAction(newPage);
    };

    const handleChangeRowsPerPage = (event: any) => {
        props.onPageSizeChangeAction(parseInt(event.target.value, 10));
    };

    const filterOnChange = (key: string, value: string) => {
        setCandidateNameFilter(value);
        if ((value && value.length > 2) || value === "") {
            props.onFilterAction(key, value);
        }
    };

    return (
        <div className="row">
            <div className="col-md-12">
                <TableContainer>
                    <Table>
                        <TableHead>
                            <TableRow>
                                {columnsInterviews.map((column: any) => (
                                    <TableCell key={column.key} className="table-header-cell">
                                        {column.sort &&
                                            <TableSortLabel
                                                active={props.sortColumn === column.key}
                                                direction={props.sortColumn === column.key ? props.sortDirection : "asc"}
                                                onClick={createSortHandler(column.key)}>
                                                {column.text}
                                            </TableSortLabel>
                                        }
                                        {column.searchable &&
                                            <input type="text" placeholder="search" className="mat-header-filter-input" value={candidateNameFilter}
                                                onClick={(e) => e.stopPropagation()} onChange={(e) => filterOnChange(column.key, e.target.value)}></input>
                                        }
                                        {!column.sort &&
                                            <span>{column.text}</span>
                                        }
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {props.interviewerDashboardList.map((row: InterviewerDashboardModel, index) => (
                                <TableRow>
                                    <TableCell>{row.interviewDate}</TableCell>
                                    <TableCell>{row.timeSlotName}</TableCell>
                                    <TableCell>{row.primarySkillName}</TableCell>
                                    <TableCell>{row.candidateName}</TableCell>
                                    <TableCell>{row.interviewTypeName}</TableCell>
                                    <TableCell>{row.isConfirmedByInterviewer}</TableCell>
                                    <TableCell>{row.rating}</TableCell>
                                    <TableCell>{row.statusName}</TableCell>
                                    <TableCell>{row.meetingURL}</TableCell>
                                    {/* <TableCell>{row.meetingURL}</TableCell> */}
                                    <TableCell>
                                        {row.isConfirmedByInterviewer === ConfirmEnum.Yes &&
                                            <div>
                                                <a onClick={() => { props.onConfirmInterviewAction(row); }} title="View Interview">
                                                    <i className="fa fa-eye grid-icon blue"></i>
                                                </a>
                                                <a onClick={() => { props.onRateInterviewAction(row); }} title="Rate Interview">
                                                    <i className="fa fa-star grid-icon blue"></i>
                                                </a>
                                            </div>
                                        }
                                        {row.isConfirmedByInterviewer !== ConfirmEnum.Yes &&
                                            <div>
                                                <a onClick={() => { props.onConfirmInterviewAction(row); }} title="Confirm Interview">
                                                    <i className="fa fa-check grid-icon blue"></i>
                                                </a>
                                            </div>
                                        }
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={props.pageSizesList}
                    component="div"
                    count={props.totalSize}
                    rowsPerPage={props.pagesize}
                    page={props.currentPage - 1}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </div>
        </div>
    );
};
export default InterviewerDashboardGridComponent;