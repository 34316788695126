import { useState, useEffect } from "react";
import { useAppSelector } from "../../../context-store";
import CompanyClasses from "../../../landing-pages/Company/company.module.css";
import PagedListModel from "../../../models/common/PagedListModel";
import { SortDirection } from "../../../models/enums/SortDirectionEnum";
import relatedShortlistedProfilesService from "../../../services/company/related-shortlisted-profiles-service";
import alertUtils from "../../../utils/toaster-utils";
import IdNameModel from "../../../models/common/IdNameModel";
import RelatedProfilesGridComponent from "./RelatedProfilesGridComponent";
import RelatedProfilesSearchInputModel from "../../../models/company/relatedAndShortlistedProfiles/request/RelatedProfilesSearchInputModel";
import RelatedProfilesGridModel from "../../../models/company/relatedAndShortlistedProfiles/response/RelatedProfilesGridModel";
import RelatedProfilesRequestModel from "../../../models/company/relatedAndShortlistedProfiles/request/RelatedProfilesRequestModel";
import modalUtils from "../../../utils/modal-utils";
import { GeneralPoupConfig, ModalSize } from "../../../models/modal-types";
import addProfilesService from "../../../services/company/add-profiles-service";
import RatingDetailsPopUp from "../../candidate/dashboard/RatingDetailsComponent";
import { Accordion, Card, Button } from "react-bootstrap";
import loaderUtils from "../../../utils/loader-utils";
import { PagingEnum } from "../../../models/enums/IAIEnum";
import masterDataService from "../../../services/master-data-service";

interface RelatedProfilesProps {
    cityList: IdNameModel[];
    experienceList: IdNameModel[];
    ratingList: IdNameModel[];
    requirementId: string;
};

const RelatedProfilesComponent = (props: RelatedProfilesProps) => {
    let loggedInUser = useAppSelector((state) => state.authState.loggedInUser);
    const [relatedProfilesPagedList, setRelatedProfilesPagedList] = useState<PagedListModel<RelatedProfilesGridModel>>();
    const [relatedProfilesList, setRelatedProfilesList] = useState<RelatedProfilesGridModel[]>([]);
    const [refreshRelatedProfiles, setRefreshRelatedProfiles] = useState<number>(2);
    const [totalRatingSearch, setTotalRatingSearch] = useState<number>(0);
    const [citySearch, setCitySearch] = useState<number>(0);
    const [experienceSearch, setExperienceSearch] = useState<number>(0);
    const [page, setPage] = useState<number>(PagingEnum.page);
    const [pageSize, setPageSize] = useState<number>(PagingEnum.pageSize);
    const [sortColumn, setSortColumn] = useState<string>("CandidateName");
    const [sortDirection, setSortDirection] = useState<number>(PagingEnum.sortDirection);
    const [pageSizesList, setPageSizesList] = useState<number[]>([]);

    useEffect(() => {
        loadPageSizes();
        getRelatedProfiles(getSearchFilters(page, pageSize, sortColumn, sortDirection));
    }, []);

    const loadPageSizes = () => {
        masterDataService.loadPageSizesForPaging().then((res) => {
            setPageSizesList(res);
        }).catch((error) => {
            alertUtils.showError(error);
        });
    };

    const getSearchFilters = (page: number, pageSize: number, sortColumn: string, sortDirection: number) => {
        var inputData: RelatedProfilesSearchInputModel = {
            requirementId: props.requirementId,
            totalRating: totalRatingSearch === 0 ? null : totalRatingSearch,
            cityId: citySearch === 0 ? null : citySearch,
            experienceId: experienceSearch === 0 ? null : experienceSearch,
            page: page,
            pageSize: pageSize,
            sort: sortColumn,
            sortDir: sortDirection
        };
        return inputData;
    };

    const getRelatedProfiles = (inputData: RelatedProfilesSearchInputModel) => {
        setRefreshRelatedProfiles(2);
        if (loggedInUser?.profileId) {
            modalUtils.showLoader(loaderUtils.getLoadDataText());
            relatedShortlistedProfilesService.getRelatedProfilesGrid(inputData).then((res) => {
                modalUtils.closeModal();
                setRelatedProfilesList([]);
                setRelatedProfilesPagedList(res);
                setRelatedProfilesList(res.items);
                setRefreshRelatedProfiles(refreshRelatedProfiles * 5);
            }).catch((error) => {
                modalUtils.closeModal();
                alertUtils.showError(error);
            });
        }
    };

    const onShortlistProfileHandler = (objShortlistProfile: RelatedProfilesRequestModel) => {
        setRefreshRelatedProfiles(2);
        modalUtils.showLoader(loaderUtils.getSaveDataText());
        relatedShortlistedProfilesService.saveRelatedProfile(objShortlistProfile).then((res) => {
            modalUtils.closeModal();
            if (res.isSuccess) {
                alertUtils.showSuccess(res.informationMessages.toString());
            } else {
                alertUtils.showError(res.errorMessages.toString());
            }
            getRelatedProfiles(getSearchFilters(page, pageSize, sortColumn, sortDirection));
        }).catch((error) => {
            setRefreshRelatedProfiles(refreshRelatedProfiles * 5);
            modalUtils.closeModal();
            alertUtils.showError(error);
        });
    };

    const onRatingDetailsHandler = (objShortlistProfile: RelatedProfilesRequestModel, hasAccess: boolean) => {
        if (hasAccess) {
            modalUtils.showLoader(loaderUtils.getLoadDataText());
            addProfilesService.interviewRatingDetails(objShortlistProfile.interviewId).then((res) => {
                modalUtils.closeModal();
                if (res.isSuccess) {
                    const config: GeneralPoupConfig = {
                        title: "Rating Details",
                        content: (
                            <RatingDetailsPopUp
                                objRatingDetails={res.data}
                            />
                        ),
                        size: ModalSize.XL,
                        className: "model-rating-details-badge"
                    };
                    modalUtils.showPopup(config);
                } else {
                    alertUtils.showError(res.errorMessages.toString());
                }
            }).catch((error) => {
                modalUtils.closeModal();
                alertUtils.showError("Erorr fetching Schedules");
            });
        } else {
            setRefreshRelatedProfiles(2);
            modalUtils.showLoader(loaderUtils.getSaveDataText());
            relatedShortlistedProfilesService.saveCandidateAccessToViewProfile(objShortlistProfile).then((res) => {
                modalUtils.closeModal();
                if (res.isSuccess) {
                    alertUtils.showSuccess(res.informationMessages.toString());
                } else {
                    alertUtils.showError(res.errorMessages.toString());
                }
                getRelatedProfiles(getSearchFilters(page, pageSize, sortColumn, sortDirection));
            }).catch((error) => {
                setRefreshRelatedProfiles(refreshRelatedProfiles * 5);
                modalUtils.closeModal();
                alertUtils.showError(error);
            });
        }
    };

    const onPageChangeActionHandler = (newPage: number) => {
        setPage(newPage);
        getRelatedProfiles(getSearchFilters(newPage, pageSize, sortColumn, sortDirection));
    };

    const onPageSizeChangeActionHandler = (newPageSize: number) => {
        setPageSize(newPageSize);
        setPage(PagingEnum.page);
        getRelatedProfiles(getSearchFilters(PagingEnum.page, newPageSize, sortColumn, sortDirection));
    };

    return (
        <div className="card card-white">
            <div className="card-body">
                <Accordion className="filter-accordian">
                    <Card className="card-border-bottom filter-card">
                        <Card.Header>
                            <Accordion.Toggle as={Button} variant="link" eventKey="0">
                                Apply Filter
                                <i className="fa fa-angle-down"></i>
                            </Accordion.Toggle>
                        </Card.Header>
                        <Accordion.Collapse eventKey="0">
                            <Card.Body>
                                <div className="row">
                                    <div className="col-md-3">
                                        <label>Total Rating</label>
                                        <select className="mb-3 form-control form-select" onChange={(e) => setTotalRatingSearch(parseInt(e.target.value))}>
                                            <option value="0">All</option>
                                            {props.ratingList.map((item, index) => {
                                                return (
                                                    <option key={index} value={item.id}> {item.name} </option>
                                                );
                                            })}
                                        </select>
                                    </div>
                                    <div className="col-md-3">
                                        <label>Location</label>
                                        <select className="mb-3 form-control form-select" onChange={(e) => setCitySearch(parseInt(e.target.value))}>
                                            <option value="0">All</option>
                                            {props.cityList.map((item, index) => {
                                                return (
                                                    <option key={index} value={item.id}> {item.name} </option>
                                                );
                                            })}
                                        </select>
                                    </div>
                                    <div className="col-md-3">
                                        <label>Experience</label>
                                        <select className="mb-3 form-control form-select" onChange={(e) => setExperienceSearch(parseInt(e.target.value))}>
                                            <option value="0">All</option>
                                            {props.experienceList.map((item, index) => {
                                                return (
                                                    <option key={index} value={item.id}> {item.name} </option>
                                                );
                                            })}
                                        </select>
                                    </div>
                                    <div className="col-md-3">
                                        <button className="btn btn-warning" type="submit" onClick={() => getRelatedProfiles(getSearchFilters(page, pageSize, sortColumn, sortDirection))}>
                                            <i className="icon-search">Search</i>
                                        </button>
                                    </div>
                                </div>
                            </Card.Body>
                        </Accordion.Collapse>
                    </Card>
                </Accordion>
                {refreshRelatedProfiles > 2 &&
                    <RelatedProfilesGridComponent
                        key={refreshRelatedProfiles}
                        relatedProfilesList={relatedProfilesList}
                        requirementId={props.requirementId}
                        totalSize={relatedProfilesPagedList?.totalCount ? relatedProfilesPagedList?.totalCount : relatedProfilesList.length}
                        currentPage={page + 1}
                        pagesize={pageSize}
                        sortColumn={sortColumn}
                        sortDirection={sortDirection === SortDirection.Asc ? "asc" : "desc"}
                        pageSizesList={pageSizesList}
                        onShortlistProfile={onShortlistProfileHandler}
                        onRatingDetails={onRatingDetailsHandler}
                        onPageChangeAction={onPageChangeActionHandler}
                        onPageSizeChangeAction={onPageSizeChangeActionHandler} />
                }
            </div>
        </div>
    );
};
export default RelatedProfilesComponent;