import { useState } from "react";
import { useAppSelector } from "../../../../context-store";
import adminClasses from "../../../landing-pages/admin/admin.module.css";
import SecondarySkillRequestModel from "../../../../models/admin/skill/request/SecondarySkillRequestModel";
import SecondarySkillModel from "../../../../models/admin/skill/response/SecondarySkillModel";
import { PageModeEnum } from "../../../../models/enums/PageModeEnum";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import TablePagination from "@mui/material/TablePagination";
import TableContainer from "@mui/material/TableContainer";
import TableSortLabel from "@mui/material/TableSortLabel";
import TableHead from "@mui/material/TableHead";

interface SecondarySkillGridProps {
    secondarySkillList: SecondarySkillModel[];
    totalSize: number;
    currentPage: number;
    pagesize: number;
    sortColumn: string;
    sortDirection: "asc" | "desc";
    pageSizesList: number[];
    primarySkillFilterValue: string;
    secondarySkillFilterValue: string;
    onSecondarySkillAction: (row: SecondarySkillRequestModel, actionType: string) => void;
    onPageChangeAction: (newPage: number) => void;
    onPageSizeChangeAction: (newPageSize: number) => void;
    onSortAction: (newSortColumn: string, sortDir: string) => void;
    onFilterAction: (filterColumn: string, filterValue: string) => void;
}

const SecondarySkillGridComponent = (props: SecondarySkillGridProps) => {
    const [primarySkillFilter, setPrimarySkillFilter] = useState<string>(props.primarySkillFilterValue);
    const [secondarySkillFilter, setSecondarySkillFilter] = useState<string>(props.secondarySkillFilterValue);
    let loggedInUser = useAppSelector((state) => state.authState.loggedInUser);
    
    const editSecondarySkill = (row: SecondarySkillModel) => {
        if (loggedInUser !== null) {
            let secondarySkillRequestModel: SecondarySkillRequestModel = {
                secondarySkillId: row.secondarySkillId,
                primarySkillId: row.primarySkillId,
                secondarySkillName: row.secondarySkillName,
                description: row.description,
                userId: loggedInUser?.userId
            }
            props.onSecondarySkillAction(secondarySkillRequestModel, PageModeEnum.Edit);
        }
    };

    const columnsSecondarySkill = [
        { key: "PrimarySkillName", text: "Primary Skill", sort: true, searchable: true },
        { key: "SecondarySkillName", text: "Secondary Skill", sort: true, searchable: true },
        { key: "Description", text: "Description", sort: true, searchable: false },
        { key: "Actions", text: "Actions", sort: false, searchable: false }
    ];

    const createSortHandler = (property: string) => (event: React.MouseEvent) => {
        props.onSortAction(property, props.sortDirection === "asc" ? "desc" : "asc")
    };

    const handleChangePage = (event: any, newPage: number) => {
        props.onPageChangeAction(newPage);
    };

    const handleChangeRowsPerPage = (event: any) => {
        props.onPageSizeChangeAction(parseInt(event.target.value, 10));
    };

    const filterOnChange = (key: string, value: string) => {
        if(key === "PrimarySkillName"){
            setPrimarySkillFilter(value);
        } else {
            setSecondarySkillFilter(value);
        }        
        if ((value && value.length > 2) || value === "") {
            props.onFilterAction(key, value);
        }
    };

    return (
        <div className="row">
            <div className="col-md-12">
            <TableContainer>
                    <Table>
                        <TableHead>
                            <TableRow>
                                {columnsSecondarySkill.map((column: any) => (
                                    <TableCell key={column.key} className="table-header-cell">
                                        {column.sort &&
                                            <TableSortLabel
                                                active={props.sortColumn === column.key}
                                                direction={props.sortColumn === column.key ? props.sortDirection : "asc"}
                                                onClick={createSortHandler(column.key)}>
                                                {column.text}
                                            </TableSortLabel>
                                        }
                                        {column.searchable &&
                                            <input type="text" placeholder="search" className="mat-header-filter-input" value={column.key === "PrimarySkillName" ? primarySkillFilter : secondarySkillFilter}
                                                onClick={(e) => e.stopPropagation()} onChange={(e) => filterOnChange(column.key, e.target.value)}></input>
                                        }
                                        {!column.sort &&
                                            <span>{column.text}</span>
                                        }
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {props.secondarySkillList.map((skill: SecondarySkillModel, index) => (
                                <TableRow>
                                    <TableCell>{skill.primarySkillName}</TableCell>
                                    <TableCell>{skill.secondarySkillName}</TableCell>
                                    <TableCell>{skill.description}</TableCell>
                                    <TableCell>
                                        <a onClick={() => { editSecondarySkill(skill); }} title="Edit">
                                            <i className="far fa-edit grid-icon blue"></i>
                                        </a>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={props.pageSizesList}
                    component="div"
                    count={props.totalSize}
                    rowsPerPage={props.pagesize}
                    page={props.currentPage - 1}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </div>
        </div>
    );
};
export default SecondarySkillGridComponent;